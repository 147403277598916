html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: " ";
  content: none;
}

.container-fluid {
  margin: 0;
  padding: 0;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  display: inline-block;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

input {
  background: transparent;
  border-color: transparent;
  border: none;
  outline: none;
}

input {
  max-width: 270px; /* You can adjust this width as needed */
  padding: 10px; /* Add some padding to make it look better */
}

html::-webkit-scrollbar {
  background-color: #000;
  width: 5px;
}
html::-webkit-scrollbar-thumb {
  background-color: #C5A059;
  width: 3px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Commodus-reg";
  src: url("../fonts/TTF/Commodus-Regular.ttf");
}
@font-face {
  font-family: "Commodus";
  font-weight: bold;
  src: url("../fonts/TTF/Commodus-Greek.ttf");
}
@font-face {
  font-family: "Commodus-alt";
  font-weight: bold;
  src: url("../fonts/TTF/Commodus-GreekAlt.ttf");
}
body {
  font-family: "Commodus";
}

.wrapper {
  overflow: hidden;
}

.dashboard {
  margin: 0;
  background-image: url(../images/main_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.dashboard .main_block {
  padding-top: 55px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dashboard .main_left {
  flex: 0 0 auto;
  width: 19.7%;
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(107deg, rgba(0, 0, 0, 0.1) 0%, rgba(29, 29, 29, 0.1) 18.75%, rgba(128, 128, 128, 0.1) 81.25%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.dashboard .main_left_img img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  margin-bottom: -5px;
}
.dashboard .main_left_top {
  padding: 80px 0px 0 0px;
  margin-bottom: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard .main_right {
  flex: 0 0 auto;
  width: 78%;
}
.dashboard .connect_btn {
  border-radius: 7.537px;
  background: linear-gradient(90deg, #C5A059 0%, #DEB750 29.52%, #E6D48A 59.72%, #F6DD75 97.29%, #B3914C 128.82%);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 20px 10px 20px;
  color: #000;
  font-size: 28.263px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 18px;
}
.dashboard .dashboard_detail {
  padding-left: 7px;
  border-left: 1px solid #000;
  opacity: 0.5;
  font-family: "Commodus-reg";
}
.dashboard .dashboard_detail .dash_title {
  color: #000;
  font-size: 28.263px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 18px;
}

.dashboard .ethereum_info {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  margin-top: 10px; /* Add margin to create space between the two sections */
}

.dashboard .ethereum_info .item_icon img {
  /* Adjust styles for the Ethereum logo as needed */
  width: 20px;
  height: 20px;
}

.dashboard .ethereum_info .item_number {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #FFF;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard .dashboard_detail .dash_title.no_active {
  opacity: 0.5;
  font-family: "Commodus";
}
.dashboard .dashboard_detail .reedem_title {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
  line-height: 0.8;
}
.dashboard .dashboard_detail .reedem_title.active {
  opacity: 1;
}
.dashboard .hero_block {
  margin-left: 20px;
  border-radius: 20px;
  background: linear-gradient(107deg, rgba(0, 0, 0, 0.1) 0%, rgba(29, 29, 29, 0.1) 18.75%, rgba(128, 128, 128, 0.1) 81.25%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 100px 74px 25px 30px;
  margin-bottom: 20px;
}
.dashboard .detail_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.dashboard .detail_item {
  flex: 0 0 auto;
  width: 23.5%;
  border-radius: 20px;
  background: linear-gradient(107deg, rgba(0, 0, 0, 0.1) 0%, rgba(29, 29, 29, 0.1) 18.75%, rgba(128, 128, 128, 0.1) 81.25%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 43px 25px 45px 25px;
  height: 200px;
}
.dashboard .detail_title {
  background: linear-gradient(89deg, #C5A059 0.32%, #DEB750 28.88%, #E6D48A 51.72%, #F6DD75 76.64%, #C1A05D 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: bolder;
  line-height: 98.9%;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Arial";
  
  /* Add text outline */
  -webkit-text-stroke: 0.5px black; /* You can adjust the width (1px) as needed */
  text-shadow: 0.5px black;
}

.dashboard .detail_title .small {
  display: block;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
}
.dashboard .item_bottom {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.dashboard .item_number {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #FFF;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dashboard .black_block {
  border-radius: 20px;
  background: #313131;
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  height: 630px;
  width: 100%;
}
.dashboard .left_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 30px;
  padding-bottom: 25px;
}
.dashboard .footer_text {
  display: flex;
  align-items: center;
  gap: 33px;
}
.dashboard .footer_text p {
  color: #FFF;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dashboard .footer_name {
  color: #929292;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dashboard .footer_social {
  display: flex;
  align-items: center;
  gap: 28px;
}
.dashboard .social_item img {
  width: 30px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.additional_text {
  /* Здесь вы можете устанавливать стили, такие как цвет текста, размер шрифта, отступы и т. д. */
  color: #333; /* Пример: установка цвета текста на тёмно-серый */
  font-size: 16px; /* Пример: установка размера шрифта */
  margin-top: 10px; /* Пример: установка отступа сверху */
  /* Другие стили, которые вы хотите применить */
}

.money_item {
  width: 48%;
  flex: 0 0 auto;
  border-radius: 20px;
  background: linear-gradient(107deg, rgba(0, 0, 0, 0.1) 0%, rgba(29, 29, 29, 0.1) 18.75%, rgba(128, 128, 128, 0.1) 81.25%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.money_top {
  padding: 24px 0 10px 0;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 20px;
  background: linear-gradient(107deg, rgba(0, 0, 0, 0.1) 0%, rgba(29, 29, 29, 0.1) 18.75%, rgba(128, 128, 128, 0.1) 81.25%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
}
.money_top .money_name {
  font-family: "arial";
  text-align: center;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  font-size: 26px;
  font-style: normal;
  font-weight: bold;
  line-height: 98.9%; /* 84.065px */
  background: linear-gradient(89deg, #C5A059 0.32%, #DEB750 28.88%, #E6D48A 51.72%, #F6DD75 76.64%, #C1A05D 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.money_wrapper {
  border-radius: 20px;
  background: linear-gradient(107deg, rgba(0, 0, 0, 0.01) 0%, rgba(29, 29, 29, 0.01) 18.75%, rgba(128, 128, 128, 0.01) 81.25%, rgba(255, 255, 255, 0.01) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 11px 32px;
  margin-bottom: 20px;
}

.money_body {
  border-radius: 20px;
  background: rgba(35, 35, 35, 0.9);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 20px;
  position: relative;
}

.top_deposit {
  color: #FFF;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  font-size: 13px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  font-family: "arial";
}

.top_money_types {
  border-radius: 20px;
  background: linear-gradient(289deg, rgba(0, 0, 0, 0.05) -100%, rgba(29, 29, 29, 0.05) -62.5%, rgba(128, 128, 128, 0.05) 62.5%, rgba(255, 255, 255, 0.05) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  padding: 0px 15px 0px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 190px;
  position: absolute;
  z-index: 3;
  height: 36px;
}
.top_money_types.top {
  right: 20px;
  top: 20px;
}
.top_money_types.bottom {
  right: 20px;
  bottom: 20px;
}
.top_money_types .money_icon img {
  margin-top: 5px;
}

.money_body_top {
  margin-bottom: 45px;
}

.money_icon_name {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  font-family: "sans-serif";
}

.money_count {
  color: #FFF;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bottom_arrow {
  margin: 5px 0 1px 0;
  text-align: center;
}
.bottom_arrow img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.money_button {
  border-radius: 20px;
  background: rgba(35, 35, 35, 0.8);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 18px 17px;
  cursor: pointer;
  
}
.money_button a {
  font-family: "Arial";
  color: #FFF;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  font-size: 24px;
  font-style: normal;
  padding: 31px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  line-height: normal;
  border-radius: 20px;
  background: linear-gradient(180deg, #F4DC7A 0%, rgba(244, 220, 122, 0) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  cursor: pointer;
}

.money_block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

#mint_section .main_right#mint .money_block {
  border-radius: 20px;
  background: linear-gradient(107deg, rgba(0, 0, 0, 0.1) 0%, rgba(29, 29, 29, 0.1) 18.75%, rgba(128, 128, 128, 0.1) 81.25%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 104px 107px 104px 107px;
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.container {
  padding: 0;
  margin: 0 auto;
  max-width: 1680px;
}

@media (max-width: 1700px) {
  .container {
    max-width: 1340px;
  }
  .dashboard .hero_block {
    padding: 60px 30px 25px 30px;
  }
  .dashboard .black_block {
    height: 500px;
  }
  .dashboard .detail_item {
    padding: 43px 0px 45px 0px;
  }
  .dashboard .detail_title {
    font-size: 24px;
  }
  .dashboard .item_number {
    font-size: 22px;
  }
  .dashboard .main_left_top {
    margin-bottom: 75px;
  }
  #mint_section .main_right#mint .money_item {
    width: 47.4%;
  }
}
@media (max-width: 1400px) {
  .container {
    max-width: 1140px;
  }
  .dashboard .hero_block {
    margin-left: 0;
  }
  .dashboard .detail_title {
    font-size: 30px;
  }
  #mint_section .main_right#mint .top_money_types.top {
    top: 41px;
  }
  .dashboard .item_bottom {
    gap: 0;
  }
  .dashboard .item_number {
    font-size: 24px;
  }
  .dashboard .main_left_top {
    padding: 55px 0px 0 0px;
  }
  #mint_section .main_right#mint .money_block {
    padding: 60px 50px 50px 50px;
    margin-left: 0px;
  }
  #mint_section .main_right#mint .money_top .money_name {
    font-size: 76px;
  }
}
@media (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
  .dashboard .main_block {
    flex-direction: column;
    gap: 20px;
  }
  #mint_section .main_right#mint .money_top {
    padding: 32px 0 12px 0;
  }
  .dashboard .main_left {
    width: 33.7%;
  }
  .dashboard .main_right {
    width: 100%;
  }
  .dashboard .detail_title .small {
    font-size: 20px;
  }
  .dashboard .main_left_top {
    margin-bottom: 0;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 720px;
  }
  .dashboard .main_left {
    width: 45.7%;
  }
  .dashboard .hero_block {
    padding: 50px 25px 25px 25px;
  }
  .dashboard .detail_block {
    flex-wrap: wrap;
    justify-content: center;
  }
  .money_item {
    width: 100%;
  }
  .money_block {
    justify-content: center;
  }
  .dashboard .detail_block {
    gap: 20px;
  }
  #mint_section .main_right#mint .money_top .money_name {
    font-size: 58px;
  }
  .money_top .money_name {
    font-size: 58px;
  }
  #mint_section .money_button a {
    font-size: 23px;
  }
  .money_button a {
    font-size: 23px;
  }
  #mint_section .main_right#mint .top_money_types {
    position: static;
  }
  .top_money_types {
    position: static;
  }
  #mint_section .main_right#mint .money_body_top {
    margin-bottom: 15px;
  }
  .money_body_top {
    margin-bottom: 15px;
  }
  #mint_section .main_right#mint .top_deposit {
    margin-bottom: 15px;
  }
  .top_deposit {
    margin-bottom: 15px;
  }
  .dashboard .detail_item {
    width: 48.5%;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
  .dashboard .main_left {
    width: 55.7%;
  }
  .dashboard .detail_item {
    width: 47.5%;
  }
  .dashboard .left_footer {
    gap: 20px 0;
  }
  #mint_section .main_right#mint .money_block {
    margin-left: 0;
    gap: 20px 0;
    justify-content: center;
  }
  #mint_section .main_right#mint .money_item {
    width: 81.4%;
  }
}
@media (max-width: 576px) {
  .container {
    padding: 15px;
    max-width: 100%;
  }
  .dashboard .main_left {
    width: 64.7%;
  }
  .dashboard .detail_item {
    width: 80.5%;
  }
  .dashboard .detail_block {
    justify-content: center;
  }
  #mint_section .main_right#mint .money_item {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .dashboard .main_left {
    width: 100%;
  }
  .dashboard .detail_item {
    width: 93.5%;
  }
  .dashboard .black_block {
    height: 400px;
  }
  .dashboard .left_footer {
    justify-content: center;
  }
  #mint_section .main_right#mint .money_block {
    padding: 40px 20px 40px 20px;
  }
  #mint_section .main_right#mint .money_wrapper {
    padding: 11px 20px;
  }
  .dashboard .left_footer {
    padding-right: 0;
    gap: 20px;
  }
}/*# sourceMappingURL=main.css.map */

